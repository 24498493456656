import { FunctionComponent, useEffect, useState } from 'react';
import { useIntersectionObserver } from 'react-intersection-observer-hook';
import dynamic from 'next/dynamic';

import StyledComponent from './styles';
import { Props } from './types';

const SectionContact = dynamic(() => import('components/modules/public/layout/sections/Contact'));

const PublicSectionContactVariantDefault: FunctionComponent<Props> = ({ ...props }) => {
    const [isCompVisible, setIsCompVisible] = useState<boolean>(false);
    const [ref, { entry }] = useIntersectionObserver();
    const isVisible = entry && entry.isIntersecting;

    useEffect(() => {
        if (isVisible && !isCompVisible) {
            setIsCompVisible(true);
        }
    }, [isVisible]);

    return (
        <StyledComponent
            className="public-section-contact-variant-default"
            ref={ref}
        >
            {isCompVisible && (
                <SectionContact {...props} />
            )}
        </StyledComponent>
    );
};

export default PublicSectionContactVariantDefault;
